
















import { Component, Vue } from 'vue-property-decorator';
import WaitingMessage from './components/WaitingMessage.vue';
@Component({
  components: { WaitingMessage },
})
export default class WaitingDiscussion extends Vue {}
